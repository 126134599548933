<template>
    <component v-if="flag" :is="selfComponent" :main-key="$route.params.key"></component>
</template>

<script>
    export default {
        data() {
            return {
                flag: true,
                selfComponent: '',
            }
        },
        created() {
            this.init();
        },
        watch: {
            '$route': function () {
                this.init();
            }
        },
        methods: {
            init() {
                this.flag = false
                try {
                    this.selfComponent = require(`@/pages/store/${this.$route.params.key}`).default;
                } catch (e) {
                    this.selfComponent = require('@/pages/store/common').default;
                }
                this.$nextTick(()=>{
                    this.flag = true
                })
            },
        },
    }
</script>