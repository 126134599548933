<style lang="scss">
@import "~@/assets/css/var";

.popup-class {
    font-size: 12px;
    max-height: 390px;
    overflow-y: auto;
    background: linear-gradient(180deg, #D1E0FC 0%, rgba(255, 255, 255, 1) 32%);

    &::-webkit-scrollbar {
        display: none;
    }

    &__i {
        color: $main;
    }
}

.bidding-info {

    // flex: unset !important;
    &-content {
        display: flex;

        &_left {
            width: 75%;

            .flex-item {
                height: 100%;

                .el-table {
                    height: 100%;

                    .icon {
                        color: $main;
                        cursor: pointer;
                    }

                    .up {
                        color: $red;
                    }

                    .down {
                        color: $green;
                    }
                }

                .flex {
                    padding-bottom: 0 !important;
                }
            }
        }

        &_right {
            width: 20%;
            margin: 20px 20px 20px 0;
            background: #fff;
            padding: 10px;

            .price-content {
                height: 70vh;
                overflow: auto;
                padding: 10px;
                background: #F3F5F8;

                .price-head {
                    font-weight: bold;
                    font-size: 15px;
                }

                .price-item {
                    background: #fff;
                    padding: 10px;
                    margin-top: 10px;
                    border-radius: 10px;

                    &_title {
                        font-weight: bold;
                        margin-bottom: 5px;

                        .iconfont {
                            margin-right: 5px;
                            font-weight: 400;
                        }
                    }

                    &_content {
                        .el-table {
                            .custom-table-head {
                                background-color: #E3EBFE;
                                color: #424242;
                            }

                            .custom-color {
                                color: $main;
                                cursor: pointer;
                            }

                            // .el-table__header{
                            //     background-color: #E3EBFE;
                            // }
                        }

                        .no-data {
                            line-height: 100px;
                            text-align: center;
                        }

                        .news-list {
                            padding: 0 20px;

                            .news-item {
                                display: flex;
                                padding-bottom: 5px;

                                .drugs-origin {
                                    flex: 1;
                                    color: $main;
                                    cursor: pointer;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }

                    .up {
                        .iconfont {
                            color: $red;
                        }
                    }

                    .down {
                        .iconfont {
                            color: $green;
                        }
                    }

                    .plat {
                        .iconfont {
                            color: $main;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div class="common-table bidding-info" v-if="loaded">
        <div class="common-table__title flex">
            <!-- 药品招投标 -->
            全国药品价格
            <el-popover placement="right-start" width="400" trigger="hover" popper-class="popup-class">
                <i slot="reference" class="el-icon-info popup-class__i" style="color: #45d75f;"></i>
                <i></i>
                <p class="through-page-tips" style="white-space: pre-wrap;">
                    1.数据计算范围<br>
                    1.1连续5年数据（2018-2023）；<br>
                    1.2价格为空，不列入计算；<br>
                    1.3执行标的：激活中,禁用,不执行,终止,待执行,未中选；不纳入计算范围；<br>
                    1.4价格属性：不符合申报、不予挂网、取消挂网，不纳入计算范围；<br>
                    1.5计算全国价格维度：药品+企业+规格+转化比；<br>
                    1.6计算省份价格维度：省份+药品+企业+规格+转化比；<br>
                    <br>
                    2.近期趋势计算原则【6项定义】<br>
                    2.1近期上涨：最近一次相较于上一次上涨；<br>
                    2.2近期下跌：最近一次相较于上一次下跌；<br>
                    2.3近期持平：最近一次相较于上一次持平；<br>
                    2.4近期暴涨：在近三次价格中（关系为且）<br>
                    2.4.1最新中标价大于均价；<br>
                    2.4.2最新中标价大于上次中标价*1.2；<br>
                    2.4.3最新中标价大于等于最高价；<br>
                    2.5近期暴跌：在近三次价格中（关系为且）<br>
                    2.5.1最新中标价小于均价；<br>
                    2.5.2最新中标价小于上次中标价*0.8；<br>
                    2.5.3最新中标价小于等于最低价；<br>
                    2.6空：仅1条数据值，默认为空；<br>
                    <br>
                    3.近5年趋势【9项定义】<br>
                    3.1持续上涨：上涨的幅度大于下跌的幅度，且每次数据都比上一次大；<br>
                    3.2持续下跌：上涨的幅度小于下跌的幅度，且每次数据都比上一次小；<br>
                    3.3持续持平：上涨的幅度等于下跌的幅度，且每次数据都等于上一次；<br>
                    3.4整体持平：上涨的幅度等于下跌的幅度，且不满足持续持平；<br>
                    3.5稳定上涨：上涨的幅度大于下跌的幅度，且整体的涨跌次数中，上涨的频率>60%,（总次数需去除不变的次数）；且最大跌幅小于最大涨幅的50%；<br>
                    3.6稳定下跌：上涨的幅度小于下跌的幅度；且整体的涨跌次数中，下跌的频率>60%,（总次数需去除不变的次数）；且最大涨幅小于最大跌幅的50%；<br>
                    3.7震荡上涨：上涨的幅度大于下跌的幅度；且不满足持续上涨和稳定上涨；<br>
                    3.8震荡下跌：上涨的幅度小于下跌的幅度；且不满足持续下跌和稳定下跌；<br>
                    3.9空：仅1条数据值，默认为空；<br>
                </p>
            </el-popover>
            <div class="flex" style="display: inline; margin-left: 20px;"
                v-if="outSearchCols && outSearchCols.length > 0">
                <div v-for="col in outSearchCols" :key="col.id" style="margin-right: 5px;">
                    <el-input v-if="col.listQueryModel === $variable.searchType.LIKE" style="width: 220px"
                        v-model="outSearchForm[col.fieldName]" :placeholder="'请输入' + col.name"></el-input>
                    <el-select v-else-if="col.listQueryModel === $variable.searchType.SELECT"
                        v-model="outSearchForm[col.fieldName]" filterable remote :placeholder="'请选择' + col.name"
                        clearable multiple collapse-tags @focus="selectRemote(col, undefined, 'focus')" reserve-keyword
                        style="width: 240px" :remote-method="(argument) => { selectRemote(col, argument) }"
                        :loading="col.loading">
                        <el-option v-for="(child, key) in col.list" :key="key"
                            :value="col.useKey ? child.key : child.value" :label="child.value"></el-option>
                    </el-select>
                </div>
                <div style="margin-left: 5px;"><el-button type="primary" @click="outSearch">筛选</el-button></div>
            </div>
        </div>
        <div class="bidding-info-content">
            <div class="ts-table bidding-info-content_left">
                <ts-table ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam"
                    :loading="loading" :name="config.key" @handleSort="sort" :error="pageParam.error"
                    :error-code="error" @handleRowDbClickChange="handleRowDbClickChange" full
                    @handleSizeChange="handleSizeChange" hide-detail @handleCurrentChange="handleCurrentChange"
                    :table-row-style="tableRowStyle" :is-stripe="isStripe">
                    <template v-slot:slot_price="{ props }">
                        <div @click="showCanvas(props.row)" class="icon">
                            <i class="iconfont icon-a-rongqi1"></i>
                        </div>
                    </template>
                    <template v-slot:slot_upsDownsRate="{ props }">
                        <div :class="[props.row.upsDownsRate > 0 ? 'up' : 'down']">
                            {{ props.row.upsDownsRate | filterRate }}
                        </div>
                    </template>
                    <template v-slot:slot_priceState="{ props }">
                        <div :class="[props.row.upsDownsRate > 0 ? 'up' : props.row.upsDownsRate < 0 ? 'down' : '']">
                            {{ props.row.priceState }}
                        </div>
                    </template>
                </ts-table>
            </div>
            <div class="bidding-info-content_right" v-loading="provInfo.loading">
                <div class="price-content">
                    <div class="price-head">
                        省份药品价格
                    </div>
                    <div class="price-item">
                        <div class="price-item_title up">
                            <i class="iconfont "></i>涨价省份
                        </div>
                        <div class="price-item_content">
                            <el-table stripe :height="provInfo.height" :data="provInfo.UP" style="width: 100%"
                                header-cell-class-name="custom-table-head">
                                <el-table-column label="省份" width="80">
                                    <template slot-scope="scope">
                                        <div class="custom-color" @click="goPage(scope.row, 'province')">
                                            {{ scope.row.winningProvince }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="winningTime" align="center" label="时间"></el-table-column>
                                <el-table-column prop="lastPrice" align="center" label="近期价格"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="price-item">
                        <div class="price-item_title down">
                            <i class="iconfont icon-dijiaqushi"></i>跌价省份
                        </div>
                        <div class="price-item_content">
                            <el-table stripe :height="provInfo.height" :data="provInfo.DOWN" style="width: 100%"
                                header-cell-class-name="custom-table-head">
                                <el-table-column label="省份" width="80">
                                    <template slot-scope="scope">
                                        <div class="custom-color" @click="goPage(scope.row, 'province')">
                                            {{ scope.row.winningProvince }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="winningTime" align="center" label="时间"></el-table-column>
                                <el-table-column prop="lastPrice" align="center" label="近期价格"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="price-item">
                        <div class="price-item_title plat">
                            <i class="iconfont icon-pinghengqi"></i>持平省份
                        </div>
                        <div class="price-item_content">
                            <el-table stripe :height="provInfo.height" :data="provInfo.PLAT" style="width: 100%"
                                header-cell-class-name="custom-table-head">
                                <el-table-column label="省份" width="80">
                                    <template slot-scope="scope">
                                        <div class="custom-color" @click="goPage(scope.row, 'province')">
                                            {{ scope.row.winningProvince }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="winningTime" align="center" label="时间"></el-table-column>
                                <el-table-column prop="lastPrice" align="center" label="近期价格"></el-table-column>
                            </el-table>
                        </div>
                    </div>

                    <div class="price-item">
                        <div class="price-item_title up">
                            <i class="iconfont icon-new2"></i>最新资讯
                        </div>
                        <div class="price-item_content">
                            <div v-if="provInfo.NEWS.length !== 0" class="news-list">
                                <div class="news-item" v-for="(item, index) in provInfo.NEWS" :key="index">
                                    <div>{{ item.winningTime }}：</div>
                                    <div class="drugs-origin" @click="goPage(item)">{{ item.drugsOrigin }}</div>
                                </div>
                            </div>
                            <div v-else class="no-data">暂无数据</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ts-dialog :show.sync="bidDialog.show" title="价格趋势" width="1000px" close-on-click-modal hide-footer>
            <my-bid-compare v-if="bidDialog.show" :row="bidDialog.source" type="bidCountry"></my-bid-compare>
        </ts-dialog>
    </div>
</template>

<script>
import MyBidCompare from '@/components/MyBidCompare.vue'
import myMixin from '@/util/mixin'
export default {
    components: { MyBidCompare },
    props: ['mainKey'],
    mixins: [myMixin],
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'BiddingInfoCountry',
            pageName: '全国药品价格',

            currentParam: {
                page: 'mainf/aimBaseDrugsWinning/queryWinningPage',
                export: '',
            },
            dialog: {
                show: false,
                cols: [],

            },
            outSearchCols: [],
            key: 'aim_base_drugs_winning',
            cols: this.$help.generalCols([{
                fieldName: 'priceState',
                name: '近期趋势',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'fivePriceState',
                name: '近5年期趋势',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'upsDownsRate',
                name: '涨跌幅',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                listOrderView: '是',
                // listStipulate: 1
            },
            {
                fieldName: 'comName',
                name: '药品通用名',
                listQueryModel: 1,
                width: 40,
                listQueryView: '是',
                listView: '是',
                listOrderView: '是',
                listOutQueryView: '是',
            },
            {
                fieldName: 'compName',
                listQueryModel: 1,
                name: '企业',
                width: 60,
                listQueryView: '是',
                // listStipulate: 1
                listOrderView: '是',
                listOutQueryView: '是',
            },
            {
                fieldName: 'unifySpec',
                name: '规格',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'conversionRatio',
                name: '转换比',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
                listOrderView: '是',
            },

            {
                fieldName: 'minUnitPrice',
                name: '制剂价格',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                listOrderView: '是',
            },
            {
                fieldName: 'winningTime',
                name: '最新中标时间',
                width: 50,
                listQueryModel: 0,
                // listQueryView: '是',
                listView: '是',
                listClass: 'tc',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'minPrice',
                name: '最低价格',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'maxPrice',
                name: '最高价格',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'winProvinceNumber',
                name: '中标省份数',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                // listStipulate: 1
                listOrderView: '是',
                link: this.goBid,
            },
            {
                fieldName: 'avgPrice',
                name: '平均价格',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'propertyPrice',
                name: '价格属性',
                width: 30,
                listQueryModel: 0,
                listView: '是',
                // listStipulate: 1
                listOrderView: '是',
            },
            {
                fieldName: 'drugsOrigin',
                name: '文件',
                width: 50,
                listView: '是',
                listOrderView: '是',
            },
            {
                fieldName: 'price',
                name: '趋势',
                width: 20,
                listQueryView: '否',
                listQueryModel: 1,
                listClass: 'tc',
                listOrderView: '是',
            }]),

            bidDialog: {
                show: false,
                source: {},
                txt: '',
            },

            point: {
                show: false,
                txt: '',
            },

            provInfo: {
                height: '15vh',
                loading: false,
                DOWN: [], //下降
                PLAT: [], //持平
                UP: [], //上升
                NEWS: []
            },
            isStripe: true,
            getId: null,

            newRow: {},
        }
    },
    filters: {
        filterRate(val) {
            let str = '';
            if (val) str = val + '%';
            return str;
        }
    },
    watch:{
        'outSearchForm.comName':{
            handler(val){
                this.newOutSearchCols('compName',val && val.length <= 1)
            },
        },
    },
    created() {

        if (this.cols) {
            const cols = this.$help.deepClone(this.cols)
            this.outSearchCols = cols.filter(item => {
                return item.listQueryView === '是' && item.listOutQueryView === '是'
            })
        }
        this.$help.socket.send(this)
    },
    methods: {
        // 跳往招投标
        goBid(data) {
            this.newRow = data
            this.goPage(data, 'winProvinceNumber')
        },
        showCanvas(row) {
            this.bidDialog.show = true
            this.bidDialog.source = row
        },

        //双击
        handleRowDbClickChange(row) {
            this.isStripe = false
            this.getProvInvPrice(row)
        },
        tableRowStyle({ row }) {
            if (this.getId === row.id) {
                return {
                    "background-color": "#E3EBFE",
                };
            }
        },

        //省份药品价格
        getProvInvPrice(row) {
            this.newRow = row
            this.getId = row.id
            this.provInfo.loading = true
            this.$api.get('mainf/aimBaseDrugsWinning/queryProvinceInfo',
                { aimBaseDrugsWinningId: row.id }).then(res => {
                    this.provInfo.DOWN = res.data.DOWN
                    this.provInfo.PLAT = res.data.PLAT
                    this.provInfo.UP = res.data.UP
                    this.provInfo.NEWS = res.data.NEWS || []
                }).finally(() => {
                    this.provInfo.loading = false
                })
        },

        listComputed(list) {
            if (list && list.length !== 0) {
                this.getProvInvPrice(list[0])
            }
            return list
        },

        //招投标信息
        goPage(row, field = '') {
            console.log('跳转', row, this.newRow)
            let query = {}
            let arr = [
                { name: 'compCode', op: 'eq', value: this.newRow.compCode, },
                { name: 'comCode', op: 'eq', value: this.newRow.comCode, },
                { name: 'unifyDoseCode', op: 'eq', value: this.newRow.unifyDoseCode, },
                { name: 'unifySpecCode', op: 'eq', value: this.newRow.unifySpecCode, },
                { name: 'conversionRatio', op: 'eq', value: this.newRow.conversionRatio, },
                { name: 'price', op: 'isNotNull', value: null, },
                // { name: 'targetPerformanceTypeName', op: 'notIn', value: ['激活中', '禁用', '不执行', '终止', '待执行', '未中选'], },
            ]
            if (field === 'province') {
                query.winningProvince = row.winningProvince
                arr.push(
                    { name: 'winningProvince', op: 'eq', value: row.winningProvince },
                    { name: "winningTime", op: "ge", value: row.winningTime },
                    { name: "winningTime", op: "le", value: row.winningTime }
                )
            } else if (field === 'winProvinceNumber') {
                query.comCode = row.comCode
            } else {
                query.releaseDocuments = row.drugsOrigin
                arr.push(
                    { name: 'releaseDocuments', op: 'eq', value: row.drugsOrigin },
                    { name: "winningTime", op: "ge", value: row.winningTime },
                    { name: "winningTime", op: "le", value: row.winningTime }
                )
            }
            query.isParam = true
            arr = JSON.stringify(arr)
            this.$help.store.set('BiddingInfoCountry-BiddingInfoProvince', arr)
            const { href } = this.$router.resolve({
                path: '/store/mainf_drug_winning_informations',
                query,
            });
            window.open(href, "_blank");
        },
        newSelectRemote(row) {
            let obj = undefined
            let outSearchReal = []
            this.outSearchCols.forEach(col => {
                const val = this.outSearchForm[col.fieldName]
                if (val && val.length > 0) {
                    if (col.listQueryModel === this.$variable.searchType.SELECT) {
                        outSearchReal.push({
                            name: col.fieldName,
                            op: 'in',
                            value: val,
                        })
                    }
                }
            })
            let compObj = outSearchReal.find(item => item.name === 'comName')  //控制企业
            if (row.fieldName === 'comName') {
                this.newOutSearchCols('compName',compObj && compObj.value.length === 1)
            }
            if (compObj && compObj.value.length === 1 && row.fieldName === 'compName') {
                obj = this.$help.deepClone(compObj)
            }
            return obj
        },
        newOutSearchCols(key,obj){
            this.outSearchCols = this.outSearchCols.map(col => {
                    if (col.fieldName === key && obj) {
                        col.list = []
                    }
                    return col
                })
        },

    }
}
</script>