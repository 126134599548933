<template>
  <div class="ch-table-five">
    <div class="ch-table-five_search">
      <el-select
        class="show-cart"
        v-model="comObj.value"
        filterable
        reserve-keyword
        remote
        :remote-method="remoteMethodCom"
        @change="comChange"
        :loading="comObj.loading"
      >
        <el-option
          v-for="item in comObj.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <slot name="pdf">
        <el-button type="primary" style="margin-left: 10px" @click="leadingOut"
          >导出</el-button
        >
        <div style="display: flex; float: right; margin-top: 5px">
          制剂成本总计：
          <div style="font-weight: bold; color: #f11c1c">
            {{ makeTotal }}
          </div>
        </div>
      </slot>
      <div v-if="formula" style="padding: 10px 10px 0 0;display: flex;justify-content: space-between;">
        <span>出处：{{ formula }}</span>
        <span>说明：药品成本测算依据{{ formula }}等组方用量与实时市场药材价格测算</span>
      </div>
    </div>
    <div class="ch-table-five_content" v-loading="loading">
      <table class="ch-table-five_table" width="100%" v-loading="loading">
        <tr class="tr tr-title">
          <td class="td td-center td-one" :rowspan="list.length + 5">
            最小包装原料成本
          </td>
          <td class="td td-center" style="width: 50px">属性</td>
          <td class="td td-center" style="width: 80px">成分</td>
          <td class="td td-center" style="width: 80px">用量</td>
          <td class="td td-center" style="width: 60px">用量单位</td>
          <td class="td td-center" style="width: 80px">近期价格(元)</td>
          <td class="td td-center" style="width: 60px">计量单位</td>
          <td class="td td-center" style="width: 80px">用量转换</td>
          <td class="td td-center" style="width: 80px">金额</td>
          <td class="td td-center td-desc">说明</td>
        </tr>
        <tr class="tr" v-for="(item, index) in list" :key="index">
          <td class="td td-center" style="padding: 5px">
            {{ item.componentType }}
          </td>
          <td class="td td-center" style="padding: 5px">
            {{ item.component }}
          </td>
          <td class="td td-center" style="padding: 5px">
            <template v-if="item.componentType === '合计'">
              {{ item.useNum || "" }}
            </template>
            <el-input-number
              v-else
              @change="changeUseNum($event, item)"
              v-model="item.useNum"
              placeholder="请输入用量"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center" style="padding: 5px">
            {{ item.componentType !== "合计" ? "g" : "" }}
          </td>
          <td class="td td-center" style="padding: 5px">
            <template v-if="item.componentType === '合计'">
              {{ item.price || "" }}
            </template>
            <el-input-number
              v-else
              @change="changePrice($event, item)"
              v-model="item.price"
              placeholder="近期价格(元)"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center" style="padding: 5px">
            {{ item.componentType !== "合计" ? "kg" : "" }}
          </td>
          <td class="td td-center" style="padding: 5px">
            {{ item.useNumConvert || "" }}
          </td>
          <td class="td td-center" style="padding: 5px">{{ item.amount }}</td>
          <td class="td td-center td-desc" style="padding: 5px">
            {{
              item.componentType !== "合计"
                ? "当用量或近期价格显示为空的时候，请手动填写"
                : ""
            }}
          </td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            原料药价格=（原料1*实际投料量+原料药2*实际投料量+...原料药N*实际投料量）
          </td>
          <td class="td td-center">{{ allPrice || "" }}</td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            规格：
            <el-select v-model="specValue" placeholder="请选择规格">
              <el-option
                v-for="item in specOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="td td-center">
            <el-input-number
              @change="changeSpec"
              v-model="specRatio"
              placeholder="规格"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc">请选择规格，若无需要规格，请输入</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">最小包装数（转化比）</td>
          <td class="td td-center">
            <el-input-number
              @change="changeRatio"
              v-model="ratio"
              placeholder="转化比"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc">每粒/片/颗/丸/袋/包</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            最小包装原料成本=（原料1价格*实际投料量+原料2价格*实际投料量+...原料N价格*实际投料量）/实际生产的最小包装数量
          </td>
          <td class="td td-center td-bold td-red">{{ rawCost }}</td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr tr-all">
          <td class="td" colspan="10">最小包装辅料成本</td>
        </tr>
        <tr class="tr">
          <td class="td td-center td-one" :rowspan="2">最小包装原料成本</td>
          <td class="td" colspan="7">
            包装辅料占药品价值的比例（参考值：9%左右）
          </td>
          <td class="td td-center">
            <el-input-number
              style="width: 90px"
              @change="changeScale"
              v-model="scale"
              placeholder="比例"
              :controls="false"
            ></el-input-number>
            %
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            最小包装辅料成本=（原料药价格*包装辅料占药品价值的比例）
          </td>
          <td class="td td-center td-bold td-red">{{ auxiliaryCost }}</td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr tr-all">
          <td class="td" colspan="10">单位人工工时工资、最小包装人工工资</td>
        </tr>
        <tr class="tr tr-special">
          <td class="td td-center td-one" :rowspan="4">单位人工工时工资</td>
          <td class="td" colspan="7">月度工资福利（元/月）</td>
          <td class="td td-center">
            <el-input-number
              @change="changeMonthPrice"
              v-model="wage.monthPrice"
              placeholder="月度工资"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc">工资、福利、社保等</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            实际工作日（每月工作日不一致，一般为21-25）
          </td>
          <td class="td td-center">
            <el-input-number
              @change="changeDays"
              v-model="wage.workDays"
              placeholder="每月工作日"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">工作时长（法定每日工作时间8小时）</td>
          <td class="td td-center">{{ wage.hour }}</td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            单位人工工时工资=月度工资福利/实际工作日*每天工作时长
          </td>
          <td class="td td-center td-bold td-red">{{ wage.hourPrice }}</td>
          <td class="td td-center td-desc"></td>
        </tr>

        <tr class="tr">
          <td class="td td-center td-one" :rowspan="3">最小包装人工工资</td>
          <td class="td" colspan="7">该批产品所用的总工时</td>
          <td class="td td-center">
            <el-input-number
              @change="changePackageHour"
              v-model="packageWage.hour"
              placeholder="总工时"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc">最大限度一批药品所需生产时间</td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">实际生产的最小包装数量</td>
          <td class="td td-center">
            <el-input-number
              @change="changePackageNum"
              v-model="packageWage.num"
              placeholder="总工时"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            最小包装人工工资=(该批产品所用的总工时*单位人工工时工资)/实际生产的最小包装数量
          </td>
          <td class="td td-center td-bold td-red">
            {{ packageWage.hourPrice }}
          </td>
          <td class="td td-center td-desc"></td>
        </tr>

        <tr class="tr tr-all">
          <td class="td" colspan="10">燃料动力费</td>
        </tr>
        <tr class="tr">
          <td class="td td-center td-one" :rowspan="3">燃料动力费</td>
          <td class="td" colspan="7">本企业年度燃料动力费</td>
          <td class="td td-center">
            <el-input-number
              v-model="powerCost.yearAmount"
              placeholder="燃料动力费"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">本年度生产的最小包装总数</td>
          <td class="td td-center">
            <el-input-number
              v-model="powerCost.yearNum"
              placeholder="包装总数"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            燃料动力费=企业年燃料动力费/本年度生产的最小包装总数
          </td>
          <td class="td td-center td-bold td-red">{{ powerCostAmount }}</td>
          <td class="td td-center td-desc"></td>
        </tr>

        <tr class="tr tr-all">
          <td class="td" colspan="10">制造费用</td>
        </tr>
        <tr class="tr tr-special">
          <td class="td td-center td-one" :rowspan="4">制造费用</td>
          <td class="td" colspan="7">设备价值（各种用于生产的自动化设备）</td>
          <td class="td td-center">
            <el-input-number
              v-model="makeCost.price"
              placeholder="设备价值"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">最大使用年限</td>
          <td class="td td-center">
            <el-input-number
              v-model="makeCost.useYear"
              placeholder="使用年限"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">最小包装总数</td>
          <td class="td td-center">
            <el-input-number
              v-model="makeCost.num"
              placeholder="包装总数"
              :controls="false"
            ></el-input-number>
          </td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr">
          <td class="td" colspan="7">
            制造费用=设备价值/使用年限/最小包装总数
          </td>
          <td class="td td-center td-bold td-red">{{ makeCostAmount }}</td>
          <td class="td td-center td-desc"></td>
        </tr>
        <tr class="tr tr-special">
          <td class="td td-one">制剂成本总计</td>
          <td class="td" colspan="7">
            最小制剂成本总计=最小包装原料成本+最小包装辅料成本+最小包装人工工资+燃料动力费+制造费用
          </td>
          <td class="td td-center td-bold td-red">{{ makeTotal }}</td>
          <td class="td td-center td-desc">每丸价格</td>
        </tr>
        <tr class="tr">
          <td class="td td-one">包装成本总计</td>
          <td class="td" colspan="7">
            最小包装成本=最小制剂成本*最小包装数（转化比）
          </td>
          <td class="td td-center td-bold td-red">{{ packageTotal }}</td>
          <td class="td td-center td-desc">每盒价格</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      comObj: {
        value: "",
        loading: false,
        options: [],
        comName: "",
      },
      dataSource: {
        value: "",
        loading: false,
        options: [],
        comName: "",
      },
      loading: false,
      formula: "", //配方
      specValue:'',//规格选项
      specOptions:[],//规格
      specRatio:undefined, //规格数值
      list: [],
      allPrice: undefined,
      ratio: undefined, // 转化比
      rawCost: undefined, // 原料成本
      scale: 9, // 比例
      auxiliaryCost: undefined, // 辅料成本
      //单位人工工时工资
      wage: {
        monthPrice: undefined,
        workDays: undefined,
        hour: 8,
        hourPrice: "",
      },
      //最小包装人工工资
      packageWage: {
        hour: undefined,
        num: undefined,
        hourPrice: "",
      },
      //燃料动力费
      powerCost: {
        yearAmount: undefined,
        yearNum: undefined,
        // amount: ''
      },
      //制造费用
      makeCost: {
        price: undefined,
        useYear: undefined,
        num: undefined,
        // amount: ''
      },
    };
  },

  watch: {
    list: {
      handler(val) {
        if (val && val.length !== 0) {
          let total = 0;
          this.list.forEach((item) => {
            total += Number(item.amount) || 0;
          });
          this.allPrice = total && total.toFixed(2);
          if (this.ratio) {
            this.rawCost = this.$help.accDiv(total, this.ratio).toFixed(2);
          }
        }
      },
      deep: true,
    },

    rawCost(val) {
      if (val && this.scale) {
        this.auxiliaryCost = this.$help
          .accMul(val, this.scale / 100)
          .toFixed(2);
      }
    },
  },

  computed: {
    // 燃料动力费
    powerCostAmount() {
      // 燃料动力费 = 企业年燃料动力费 / 本年度生产的最小包装总数
      if (this.powerCost.yearAmount && this.powerCost.yearNum) {
        return this.$help
          .accDiv(this.powerCost.yearAmount, this.powerCost.yearNum)
          .toFixed(2);
      } else {
        return "";
      }
    },
    // 制造费用
    makeCostAmount() {
      // 制造费用 = 设备价值 / 使用年限 / 最小包装总数
      if (this.makeCost.price && this.makeCost.useYear && this.makeCost.num) {
        let rate = this.$help.accDiv(
          this.makeCost.price,
          this.makeCost.useYear
        );
        return this.$help.accDiv(rate, this.makeCost.num).toFixed(2);
      } else {
        return "";
      }
    },
    // 制剂成本总计
    makeTotal() {
      //最小制剂成本总计 = 最小包装原料成本 + 最小包装辅料成本 + 最小包装人工工资 + 燃料动力费 + 制造费用
      let total =
        this.changeZero(this.rawCost) +
        this.changeZero(this.auxiliaryCost) +
        this.changeZero(this.packageWage.hourPrice) +
        this.changeZero(this.powerCostAmount) +
        this.changeZero(this.makeCostAmount);
      if (total) return total.toFixed(2);
      else return "";
    },
    //包装成本总计
    packageTotal() {
      // 最小包装成本 = 最小制剂成本 * 最小包装数（转化比）
      if (this.ratio && this.makeTotal) {
        return this.$help.accMul(this.makeTotal, this.ratio).toFixed(2);
      } else return "";
    },
  },

  filters: {
    changePirce(val) {
      let str = "";
      if (val) str = this.$help.accDiv(val, 1000);
      return str;
    },
  },
  mounted() {
    this.getComList("苏合香丸", true);
    this.getDataSource("", true);
  },
  methods: {
    leadingOut() {
      this.$help.exportInfo(
        "确定导出吗",
        "chm/ChineseMaterial/exportChineseMaterialPrice",
        {
          comName: this.comObj.comName,
          ratio: this.ratio,
          scale: this.scale,
          allPrice: this.allPrice,
          wageMonthPrice: this.wage.monthPrice,
          wageWorkDays: this.wage.workDays,
          wageHourPrice: this.wage.hourPrice,
          packageWageHour: this.packageWage.hour,
          packageWageNum: this.packageWage.num,
          powerCostYearAmount: this.powerCost.yearAmount,
          powerCostYearNum: this.powerCost.yearNum,
          makeCostPrice: this.makeCost.price,
          makeCostUseYear: this.makeCost.useYear,
          makeCosetNum: this.makeCost.num,
          packageTotal: this.packageTotal,
          rawCost: this.rawCost,
          makeCostAmount: this.makeCostAmount,
          auxiliaryCost: this.auxiliaryCost,
          makeTotal: this.makeTotal,
          powerCostAmount: this.powerCostAmount,
          packageWageHourPrice: this.packageWage.hourPrice,
          componentList: this.list.map((item) => {
            return {
              componentType: item.componentType,
              component: item.component,
              useNum: item.useNum,
              price: item.price,
              useNumConvert: item.useNumConvert,
              amount: item.amount,
            };
          }),
        }
      );
    },
    changeZero(val) {
      if (val) return val - 0;
      else return 0;
    },
    clearData() {
      this.allPrice = undefined;
      this.specRatio = undefined; // 规格数值
      this.ratio = undefined; // 转化比
      this.rawCost = undefined; // 原料成本
      this.scale = 9; // 比例
      this.auxiliaryCost = undefined; // 辅料成本
      //单位人工工时工资
      this.wage = {
        monthPrice: undefined,
        workDays: undefined,
        hour: 8,
        hourPrice: "",
      };
      //最小包装人工工资
      this.packageWage = {
        hour: undefined,
        num: undefined,
        hourPrice: "",
      };
      //燃料动力费
      this.powerCost = {
        yearAmount: undefined,
        yearNum: undefined,
        // amount: ''
      };
      //制造费用
      this.makeCost = {
        price: undefined,
        useYear: undefined,
        num: undefined,
        // amount: ''
      };
    },
    getComList(query = "", bool = false) {
      this.comObj.loading = true;
      this.$api
        .get("setting/aimBaseDrugs/queryChineseAimBaseDrugsListByKeyword", {
          keyword: query,
        })
        .then((res) => {
          let options = [];
          if (res.data && res.data.length !== 0) {
            res.data.forEach((ele) => {
              options.push({
                value: ele.comCode,
                label: ele.comName,
              });
            });
          }
          this.comObj.options = options;
          this.$nextTick(() => {
            if (bool && options.length !== 0) {
              this.comObj.value = options[0].value;
              this.comObj.comName = options[0].label;
              this.getDataInfo();
              this.getDrugSpec();
            }
          });
        })
        .finally(() => {
          this.comObj.loading = false;
        });
    },
    //药品搜索
    remoteMethodCom(query) {
      this.getComList(query);
    },

    //数据来源
    getDataSource(query = "", bool = false) {
      this.dataSource.loading = true;
      this.$api
        .get("chm/ChineseMaterial/queryDataSource", { keyword: query })
        .then((res) => {
          let options = [];
          if (res.data && res.data.length !== 0) {
            res.data.forEach((ele) => {
              options.push({
                value: ele.dataSource,
                label: ele.dataSource,
              });
            });
          }
          this.dataSource.options = options;
          this.$nextTick(() => {
            if (bool && options.length !== 0) {
              this.dataSource.value = options[0].value;
              this.dataSource.comName = options[0].label;
              this.getMaterialInfo();
            }
          });
        })
        .finally(() => {
          this.dataSource.loading = false;
        });
    },
    remoteMethodSource(query) {
      this.getDataSource(query);
    },
    sourceChange() {
      this.getMaterialInfo();
    },

    //配方
    getMaterialInfo() {
      // this.loading = true
      this.$api
        .get("chm/ChineseMaterial/queryFormulaByComCode", {
          comCode: this.comObj.value,
          dataSource: this.dataSource.value,
        })
        .then((res) => {
          this.formula = res.data;
        })
        .finally(() => {
          // this.loading = false
        });
    },

    //最近均价
    getDataInfo() {
      this.loading = true;
      this.clearData();
      this.$api
        .get("chm/ChineseMaterial/queryLastPriceByComCode", {
          comCode: this.comObj.value,
        })
        .then((res) => {
          let list = [];
          let _price = 0;
          let _useNum = 0;
          let _convert = 0;
          if (res.data && res.data.length !== 0) {
            // list =
            res.data.forEach((item) => {
              let _amount = "";
              let _useNumConvert = undefined;
              if (item.purity) {
                let num = this.$help.accDiv(item.purity, 1000);
                let orr = num && num.toFixed(2);
                _useNumConvert = orr;
                if (item.price) {
                  _amount = this.$help.accMul(num, item.price).toFixed(2);
                }
              } else {
                _useNumConvert = undefined;
                _amount = "";
              }
              list.push({
                comName: item.comName,
                component: item.component,
                componentType: item.componentType,
                price: item.price || undefined,

                useNum: item.purity || undefined, //用量
                useNumConvert: _useNumConvert, //用量转换
                amount: _amount, //金额
              });
              _price += Number(item.price) || 0;
              _useNum += Number(item.purity) || 0;
              _convert += Number(_useNumConvert) || 0;
            });
          }
          _price = _price && _price.toFixed(2);
          _useNum = _useNum && _useNum.toFixed(2);
          _convert = _convert && _convert.toFixed(2);
          list.push({
            useNum: _useNum, //用量
            useNumConvert: _convert, //用量转换
            price: _price, //近期价格
            componentType: "合计",
          });
          this.list = list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDrugSpec() {
      this.loading = true;
      this.$api
        .get("ba/baDrugSpec/queryBaDrugSpecListByComCode", {
          comCode: this.comObj.value,
        })
        .then((res) => {
          if(res.data && res.data.length!=0){
            res.data.map(item=>{
                item.value = item.specCode
                item.label = item.spec
            })
            this.specValue = res.data[0].specCode
          }
          this.specOptions = res.data
        })
        .finally(() => {
          this.loading = false;
        });
    },

    comChange(data) {
      this.comObj.comName = this.comObj.options.find((item) => {
        return item.value === data;
      }).label;
      this.getDataInfo();
      this.getDrugSpec();
      this.getMaterialInfo();
    },

    changeUseNum(val, item) {
      if (val) {
        let num = this.$help.accDiv(val, 1000);
        item.useNumConvert = num && num.toFixed(2);
        if (item.price) {
          item.amount = this.$help.accMul(num, item.price).toFixed(2);
        }
      } else {
        item.useNumConvert = undefined;
        item.amount = undefined;
      }
      this.getAmountNum();
    },
    getAmountNum() {
      let _useNum = 0; //用量合计
      let _useNumConvert = 0; //用量转换合计
      let _price = 0; //近期价格合计
      this.list.forEach((item, index) => {
        if (index !== this.list.length - 1) {
          _useNum += Number(item.useNum) || 0;
          _useNumConvert += Number(item.useNumConvert) || 0;
          _price += Number(item.price) || 0;
        }
      });
      this.list[this.list.length - 1].useNum = _useNum && _useNum.toFixed(2);
      this.list[this.list.length - 1].useNumConvert =
        _useNumConvert && _useNumConvert.toFixed(2);
      this.list[this.list.length - 1].price = _price && _price.toFixed(2);
      this.changeSpec(this.specRatio)
    },
    changePrice(val, item) {
      if (val && item.useNumConvert) {
        // 乘法函数
        item.amount = this.$help.accMul(item.useNumConvert, val).toFixed(2);
      } else {
        item.amount = undefined;
      }
      this.getAmountNum();
    },

    //规格
    changeSpec(val) {
        let  num =  this.list[this.list.length - 1].useNum
        this.ratio = Number(this.$help.accDiv(num, val).toFixed(2))
        this.changeRatio(this.ratio)
    },
    //转化比
    changeRatio(val) {
      this.rawCost = this.$help.accDiv(this.allPrice, val).toFixed(2);
    },

    //比例
    changeScale(val) {
      this.auxiliaryCost = this.$help
        .accMul(this.rawCost, val / 100)
        .toFixed(2);
    },

    //月度工资福利
    changeMonthPrice(val) {
      //单位人工工时工资 = 月度工资福利 / 实际工作日 / 每天工作时长
      if (this.wage.workDays) {
        let dayPrice = this.$help.accDiv(val, this.wage.workDays);
        this.wage.hourPrice = this.$help
          .accDiv(dayPrice, this.wage.hour)
          .toFixed(2);
        if (this.packageWage.hour && this.packageWage.num) {
          let price = this.$help.accDiv(
            this.packageWage.hour,
            this.wage.hourPrice
          );
          this.packageWage.hourPrice = this.$help
            .accDiv(price, this.packageWage.num)
            .toFixed(2);
        }
      }
    },
    changeDays(val) {
      //单位人工工时工资 = 月度工资福利 / 实际工作日 / 每天工作时长
      if (this.wage.monthPrice) {
        let dayPrice = this.$help.accDiv(this.wage.monthPrice, val);
        this.wage.hourPrice = this.$help
          .accDiv(dayPrice, this.wage.hour)
          .toFixed(2);
        if (this.packageWage.hour && this.packageWage.num) {
          let price = this.$help.accDiv(
            this.packageWage.hour,
            this.wage.hourPrice
          );
          this.packageWage.hourPrice = this.$help
            .accDiv(price, this.packageWage.num)
            .toFixed(2);
        }
      }
    },
    //最小包装人工工资
    changePackageHour(val) {
      //最小包装人工工资 = (该批产品所用的总工时 * 单位人工工时工资) / 实际生产的最小包装数量
      if (this.wage.hourPrice && this.packageWage.num) {
        let price = this.$help.accMul(val, this.wage.hourPrice);
        this.packageWage.hourPrice = this.$help
          .accDiv(price, this.packageWage.num)
          .toFixed(2);
      }
    },
    changePackageNum(val) {
      //最小包装人工工资 = (该批产品所用的总工时 * 单位人工工时工资) / 实际生产的最小包装数量
      if (this.wage.hourPrice && this.packageWage.hour) {
        let price = this.$help.accMul(
          this.packageWage.hour,
          this.wage.hourPrice
        );
        this.packageWage.hourPrice = this.$help.accDiv(price, val).toFixed(2);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/css/var";
$borderColor: #000;
$customMain: #91a1ed;

.ch-table-five {
  background: #f3f5f8;

  &_search {
    padding: 10px;

    .el-button {
      margin-left: 10px;
    }
  }

  &_content {
    height: 63vh;
    overflow: auto;
    padding: 10px 10px;
    background-color: #fff;
    border-left: 1px solid #f3f5f8;
    border-bottom: 1px solid #f3f5f8;
  }

  &_title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    line-height: 50px;
    background-color: $customMain;
    color: #fff;
    border-left: 1px solid $customMain;
    border-right: 1px solid $customMain;
    border-radius: 10px 10px 0 0;
  }

  &_table {
    border-width: 1px;
    border-spacing: 0px;
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;

    .tr-one {
      .td-one {
        border-bottom: 1px solid #fff !important;
      }

      .td {
        border-bottom: 0 !important;
      }
    }

    .tr-all {
      border-left: 1px solid $borderColor;
      border-top: 1px solid $borderColor;
    }

    .tr-title {
      background-color: $customMain;
      color: #fff;

      // .td-one {
      //     border-bottom: 1px solid #fff !important;
      // }
      .td {
        border-bottom: 0 !important;
        border-right: 1px solid #fff !important;

        &:nth-last-of-type(1) {
          border-right: 0 !important;
        }
      }
    }

    .tr-special {
      .td-one {
        border-bottom: 1px solid #fff !important;
      }
    }

    .tr {
      .td {
        padding: 10px;
        border: 0px;
        border-left: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;

        &:nth-last-of-type(1) {
          border-right: 0;
        }
      }

      &:nth-last-of-type(1) .td {
        border-bottom: 0;
      }

      .td-one {
        width: 96px;
        background-color: $customMain;
        color: #fff;
        border-left: 1px solid $customMain;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
      }

      .td-center {
        text-align: center;
        vertical-align: middle;
      }

      .td-desc {
        width: 260px;
      }

      .td-span {
        span {
          display: inline-block;
          padding: 0 5px;
        }
      }

      .td-input {
        &-list {
          display: flex;
        }

        .checkbox {
          margin-right: 20px;
          display: flex;
          align-items: center;

          .box {
            border: 1px solid #000;
            width: 18px;
            height: 18px;
            display: inline-block;
            text-align: center;
            line-height: 15px;
            margin-right: 3px;
          }

          .active {
            border: 1px solid;
            color: #000;
          }
        }
      }

      .td-bold {
        font-weight: bold;
      }

      .td-red {
        color: $red;
      }
    }
  }
}
</style>
